'use client';

import { PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { Modal, OverlayTrigger, Popover, PopoverProps } from 'react-bootstrap';
import { OverlayChildren } from 'react-bootstrap/esm/Overlay';
import { OverlayTriggerProps } from 'react-bootstrap/esm/OverlayTrigger';
import styles from './detailsModal.module.scss';

export interface PropsWithSupplementalContent extends PropsWithChildren {
	popup?: ReactNode;
	modal?: ReactNode;
	popupProps?: Partial<PopoverProps>;
	triggerProps?: Partial<OverlayTriggerProps>;
}

export function ContentWithPopup(props: PropsWithSupplementalContent): ReactNode {
	const popup = props.popup;

	const parentPopupProps = props.popupProps;
	const renderTooltip: OverlayChildren = useCallback(
		(injected): ReactNode => {
			return (
				<Popover {...injected} {...parentPopupProps}>
					{popup}
				</Popover>
			);
		},
		[popup, parentPopupProps],
	);

	if (!popup) {
		return props.children;
	}

	return (
		<OverlayTrigger
			placement="top-end"
			flip={true}
			delay={{ show: 200, hide: 100 }}
			{...props.triggerProps}
			overlay={renderTooltip}
		>
			<div>{props.children}</div>
		</OverlayTrigger>
	);
}

interface ContentModalProps extends PropsWithChildren {
	showModal: boolean;
	setShowModal: (boolean) => void;
}

export const ContentModal = (props: ContentModalProps): ReactNode => {
	const handleClose = () => {
		props.setShowModal(false);
	};

	return (
		<Modal className={styles.modal} show={props.showModal} onHide={handleClose} centered={true} scrollable={true}>
			{props.children}
		</Modal>
	);
};

export function TableRowWithModal(props: PropsWithSupplementalContent): ReactNode {
	const modal = props.modal;

	const [showModal, setShowModal] = useState(false);

	// TODO add modal

	let onClick: () => void;
	if (modal) {
		onClick = () => {
			// Toggle showing the modal
			setShowModal(!showModal);
		};
	}

	const classNames: string[] = [];
	let modalContent = null;
	if (modal) {
		classNames.push('clickable');
		modalContent = (
			<ContentModal showModal={showModal} setShowModal={setShowModal}>
				{modal}
			</ContentModal>
		);
	}

	return (
		<>
			<tr className={classNames.join(' ')} onClick={onClick}>
				{props.children}
			</tr>
			{modalContent}
		</>
	);
}

export function DivWithModal(props: PropsWithSupplementalContent): ReactNode {
	const modal = props.modal;

	const [showModal, setShowModal] = useState(false);

	// TODO add modal

	let onClick: () => void;
	if (modal) {
		onClick = () => {
			// Toggle showing the modal
			setShowModal(!showModal);
		};
	}

	const classNames: string[] = [];
	let modalContent = null;
	if (modal) {
		modalContent = (
			<ContentModal showModal={showModal} setShowModal={setShowModal}>
				{modal}
			</ContentModal>
		);
	}

	return (
		<>
			<div className={classNames.join(' ')}>
				<button type="button" className={styles.button} onClick={onClick}>
					{props.children}
				</button>
			</div>
			{modalContent}
		</>
	);
}
