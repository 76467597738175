import Link from 'next/link';
import { LinkProps } from 'next/link';
import { PropsWithChildren, ReactNode, Suspense } from 'react';
import { useCurrentURL } from './useCurrentURL';

export type ConditionalLinkProps = LinkProps & PropsWithChildren;

function ConditionalLinkContent(props: ConditionalLinkProps): ReactNode {
	const currentURL = useCurrentURL();
	const href = props.href;

	// We're already here, no need to link to anything
	if (href === currentURL) {
		return props.children;
	}

	return <Link {...props} prefetch={false} />;
}

export default function ConditionalLink(props: ConditionalLinkProps): ReactNode {
	// Wrap in Suspense due ot use of useSearchParams
	// https://nextjs.org/docs/app/api-reference/functions/use-search-params
	return (
		<Suspense>
			<ConditionalLinkContent {...props} />
		</Suspense>
	);
}
