'use client';

import { EligibilityResult } from '@dte/otw/azure-functions/src/common/interfaces/api/EligibilityResult';
import { PremiseDataDocument } from '@dte/otw/azure-functions/src/common/interfaces/containers/premises/PremiseData';
import { getPremiseEligibilityResult } from '@dte/otw/azure-functions/src/common/premises/data/attributes/eligibility/getPremiseEligibilityResult';
import { PropsWithChildren, ReactNode, useRef } from 'react';
import { Popover } from 'react-bootstrap';
import { EligibilityMessages } from '../(premiseId)/eligibility/PremiseEligibility';
import { PremiseLink } from '../(premiseId)/premiseLink/PremiseLink';
import { addClassName } from '../../../../../components/form/addClassName';
import { ContentWithPopup } from '../../orders/(orderId)/problemIndicators/RowWithSupplementalContent';
import styles from './PremiseMarker.module.scss';

const markerClassName: Record<EligibilityResult, string> = {
	[EligibilityResult.ElectricActive]: styles.active,
	[EligibilityResult.DNP]: styles.warning,
	[EligibilityResult.ElectricInactive]: styles.inactive,
	[EligibilityResult.GasOnly]: styles.inactive,
	[EligibilityResult.NoService]: styles.inactive,
	[EligibilityResult.NotFound]: styles.inactive,
};

export interface PremiseMarkerProps extends PropsWithChildren {
	premiseData: PremiseDataDocument;
	size?: 'small' | 'medium';
}

export function PremiseDetailPopup(props: PremiseMarkerProps): ReactNode {
	const premiseData = props.premiseData;

	const containerRef = useRef<HTMLDivElement>(null);

	const eligibility = getPremiseEligibilityResult(premiseData);
	const message = EligibilityMessages[eligibility];

	// IF no message, don't add a popup
	if (!message) {
		return props.children;
	}

	const tooltipClass = addClassName(styles.premiseDetailTooltip, markerClassName[eligibility]);
	const popupContent = <Popover.Body>{message}</Popover.Body>;

	return (
		<div className={tooltipClass} ref={containerRef}>
			<ContentWithPopup
				popup={popupContent}
				popupProps={{}}
				triggerProps={{ placement: 'bottom', delay: 50, container: containerRef }}
			>
				{props.children}
			</ContentWithPopup>
		</div>
	);
}

export function PremiseMarker(props: PremiseMarkerProps): ReactNode {
	const premiseData = props.premiseData;

	if (!premiseData) {
		return null;
	}

	const eligibility = getPremiseEligibilityResult(premiseData);
	let markerClass = addClassName(styles.marker, markerClassName[eligibility]);
	if (props.size === 'small') {
		markerClass = addClassName(markerClass, styles.small);
	}

	return (
		<section className={styles.premiseMarker}>
			<PremiseDetailPopup {...props}>
				<PremiseLink premiseId={premiseData.premiseId}>
					<div className={markerClass}>{props.children}</div>
				</PremiseLink>
			</PremiseDetailPopup>
		</section>
	);
}
